function slideout_menu() {
  var slideout = new Slideout({
    'panel': document.getElementById('js-so_panel'),
    'menu': document.getElementById('js-so_menu'),
    'duration': 10,
  });
  var nodeList = document.querySelectorAll('#js-so_toggle, #js-so_panel');
  var node = Array.prototype.slice.call(nodeList, 0);
  node.forEach(function (elem, index) {
    elem.addEventListener('click', function () {
      slideout.toggle();
    });
  });
}

jQuery(function ($) {
  /*********************************************************************
    common
  *********************************************************************/
  slideout_menu();
  /*********************************************************************
    index
  *********************************************************************/
  $("#js-so_menu a").on("click", function () {
    $(".-main ul").slideToggle();
    $("html").removeClass("slideout-open");
  });

  $('.js-slide').slick({
    autoplay: false,
    speed: 900,
    slideToScroll: 1,
    slidesToShow: 1,
    arrows: false,
    dots: false,
    asNavFor: '.js-slide-thum',
  });
  $('.js-slide-thum').slick({
    infinite: false,
    asNavFor: '.js-slide',
    autoplay: false,
    autoplaySpeed: 3000,
    speed: 900,
    slideToScroll: 1,
    slidesToShow: 3,
    arrows: false,
    asNavFor: '.js-slide',
    focusOnSelect: true
  });
  $('.js-slide02').slick({
    autoplay: true,
    infinite: true,
    slideToScroll: 1,
    slidesToShow: 3,
    dots: true,
    arrows: true,
    prevArrow: '<img src="./assets/img/common/icon_arrow01.png" class="slide-arrow prev-arrow">',
    nextArrow: '<img src="./assets/img/common/icon_arrow01.png" class="slide-arrow next-arrow">',
    responsive: [{
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        infinite: false,
      }
    }]
  });

});
jQuery(window).on('load', function ($) {
});
jQuery(window).on('scroll', function ($) {
});
jQuery(window).on('resize', function ($) {
});
